<template>
  <div>
    <div class="flix-form-group flix-html-small">
      <flixIcon :id="'info-sign'" /> {{ $t('message.insertEmbedCode') }}
    </div>
    <div class="flix-form-group">
      <textarea
        v-if="textarea == 'js'"
        spellcheck="false"
        @click="setFocus"
        class="flix-form-control"
        v-model="embedCode.js"
        readonly="true"
      >
      </textarea>
      <textarea
        v-if="textarea == 'iframe'"
        spellcheck="false"
        @click="setFocus"
        class="flix-form-control"
        v-model="embedCode.iframe"
        readonly="true"
      >
      </textarea>
    </div>
    <transition name="flixFadeIn">
      <div class="flix-form-group" v-if="copied" :key="copied">
        <div class="flix-alert flix-alert-info">
          {{ $t('message.copy', { name: $t('message.embedCode') }) }}
        </div>
      </div>
    </transition>
    <flixIcon :id="'log-in'" />&nbsp;
    <a
      href="#"
      class="flix-html-a flix-html-small"
      :class="{ 'flix-html-strong': textarea == 'js' }"
      @click.prevent="textarea = 'js'"
      >JavaScript</a
    >
    |
    <a
      href="#"
      class="flix-html-a flix-html-small"
      :class="{ 'flix-html-strong': textarea == 'iframe' }"
      @click.prevent="textarea = 'iframe'"
      >Iframe</a
    >
    <h3 class="flix-html-h3">{{ $tc('message.pageBuilder', 1) }}</h3>
    <div class="flix-form-group">
      <editBtn
        :settings="{ block: false }"
        :onClick="
          (data) => {
            getEdit(data)
          }
        "
      >
        <template v-slot:text>
          {{ $t('message.edit', { name: $tc('message.pageBuilder', 1) }) }}
        </template>
      </editBtn>
    </div>
    <div class="flix-form-group">
      <h3 class="flix-html-h3">{{ $t('message.qrCode') }}</h3>
      <qrCode :url="url" />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    qrCode() {
      return import('./qrCode')
    }
  },
  props: {
    id: [String, Number]
  },
  data() {
    return {
      variables: {},
      copied: false,
      textarea: 'js',
      embedCode: {
        js: '',
        iframe: ''
      },
      url: ''
    }
  },
  methods: {
    getEdit() {
      var name = 'dashboardPageBuilder'
      if (this.variables.state === 'share') {
        name = 'sharesPageBuilder'
      }
      this.$router.push({ name: name, params: { id: this.id, open: 1 } })
    },
    setFocus(event) {
      event.target.select()
      try {
        document.execCommand('copy')
        this.copied = true
        setTimeout(
          function () {
            this.copied = false
          }.bind(this),
          2000
        )
      } catch (err) {
        alert('Oops, unable to copy')
      }
    }
  },
  mounted() {
    this.variables = this.$getUserVariables()
    this.url =
      this.$flix_url +
      '/' +
      this.variables.user.language +
      '/' +
      this.variables.user.username +
      '/' +
      this.id +
      '/'
    this.embedCode.js = this.$getEmbedCode({
      user: this.variables.user.md5_id,
      ID: this.id,
      language: this.variables.user.language
    })
    this.embedCode.iframe =
      '<iframe src="' +
      this.url +
      'iframe.html" width="800px" height="600px"></iframe>'
  },
  computed: {}
}
</script>
<style lang="sass" scoped>
.flix-center
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
.flix-html-a
  display: inline-block
  max-width: 100%
  word-wrap: break-word
.flix-url
  word-wrap: break-word
textarea
  cursor: pointer
</style>
